

export default function FormButton({label, enabled, onClick, loading}){
    const isDisabled = !enabled || loading

    return (
        <div className="form-group text-center align-items-center mt-3">
            <button type="button" className="text-center w-50 btn btn-success" disabled={isDisabled} onClick={onClick}>
                { loading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> }
                <span> {label}</span>
            </button>
        </div>
    );
}