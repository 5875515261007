import 'bootstrap/dist/css/bootstrap.min.css';
import { FormDataProvider }from './FormContext'

import BMRForm from './Components/BMRForm'

function App({authScope}) {
  return (
    <FormDataProvider>
      <BMRForm authScope={authScope} />
    </FormDataProvider>
  );
}

export default App;
