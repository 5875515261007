
import cautionImage from '../Images/caution.png'

export default function BadAttachmentMessage({workRequest}){
    return (
        <div className="text-center mx-md-auto mt-5">

            <img
            src={cautionImage}
            alt="MRC Logo"
            className="m-4"
            style={{ width: "120px", padding: "0" }}
            />

            <div className="mb-5">
                <h1 className="h3 font-weight-medium">Partially submitted to Property Services!</h1>
                <p>The files provided have not been attached at this time, however the Work Request {workRequest} has still been created.</p>
            </div>

            <button type="button" className="text-center btn btn-primary" style={{ width: "300px" }} onClick={() => window.location.reload(false) }>Submit new BMR</button>
        </div>
    );
}