
export async function GetConfiguration(){
    var res = await fetch('/client_auth')
    var data = await res.json();

    return {
        msalConfig: {
            auth: data.auth,
            cache: {
                cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
                storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
            },
            system: {
                loggerOptions: {
                    loggerCallback: (level, message, containsPii) => {
                        console.info(message);
                    }
                }
            }
        },
        authScope: data.scope
    }
}