

export default function FormCheckbox({label,onChange}){

    function handleChange(e) {
        console.log(e.target.checked)
        onChange(e.target.checked);
    }

    return (
        <div className="form-group">
            <input type="checkbox" onChange={handleChange} />
            <label style={{ marginLeft: "5px" }}>{label}</label>
        </div>
    );
}