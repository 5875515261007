import React, { useState } from 'react'

export const FormDataContext = React.createContext()

export const FormDataProvider = (props) => {
    const [state, setState] = useState({ })

    const handleSetState = (object) => {
        setState({ ...state, ...object })
    }
    const value = { ...state, setState: handleSetState }

    return <FormDataContext.Provider value={value}>{props.children}</FormDataContext.Provider>
}