
export default function SuccessMessage({workRequest}){
    return (
        <div className="text-center mx-md-auto mt-5">

            <figure id="iconChecked" className="ie-height-90 mx-auto mb-3">
                <svg x="0px" y="0px" viewBox="0 0 25.6 25.6" style={{ enableBackground: "new 0 0 25.6 25.6", width: "120px" }} >

                    <path className="success-icon" d="M12.8,0C5.7,0,0,5.7,0,12.8s5.7,12.8,12.8,12.8s12.8-5.7,12.8-12.8S19.9,0,12.8,0z M19.5,8.8L12.7,19  c-0.2,0.3-0.5,0.5-0.8,0.5s-0.7-0.2-0.9-0.4l-4-4c-0.3-0.3-0.3-0.7,0-1l1-1c0.3-0.3,0.7-0.3,1,0l2.6,2.6l5.7-8.4  c0.2-0.3,0.7-0.4,1-0.2l1.2,0.8C19.7,8.1,19.8,8.5,19.5,8.8z"></path>

                </svg>
            </figure>

            <div className="mb-5">
                <h1 className="h3 font-weight-medium">Successfully submitted to Property Services!</h1>
                <p>This has been submitted under Work Request {workRequest}.</p>
            </div>

            <button type="button" className="text-center btn btn-primary" style={{ width: "300px" }} onClick={() => window.location.reload(false) }>Submit new BMR</button>
        </div>
    );
}