import { useRef, useState } from 'react'

export default function FormAttachments({label, onChange}){
    const [attachments, setAttachments] = useState([]);
    const fileInput = useRef(null);
    const maxFileSize = 1024 * 1024 * 15;

    function handleFileInput(e){
        var fr = new FileReader();
        fr.onload= () => {
            if(!fileAlreadyAttached(fileInput.current.files[0].name))
            {
                if(fileInput.current.files[0].size < maxFileSize)
                {
                    const newData = [ ...attachments, { name: fileInput.current.files[0].name , contents: fr.result.split(",").pop() }]; //Extend array with new Attachment
                    setAttachments(newData);
                    onChange(newData);
                }
                else{
                    let errorMessage = "Sorry but the file '" + fileInput.current.files[0].name + "' is too big. Please limit file size to 15MB.";
                    alert(errorMessage);
                }
            }
            else
            {
                let errorMessage = "Sorry but the file '" + fileInput.current.files[0].name + "' has already been added to this request.";
                alert(errorMessage);
            }

            fileInput.current.value = null;
        }

        fr.readAsDataURL(fileInput.current.files[0]);
    }

    function fileAlreadyAttached(filename) {
        var i = attachments.length;
        while (i--) {
           if (attachments[i].name === filename) {
               return true;
           }
        }
        return false;
    }

    function removeAttachment(id){
        const newData = attachments.filter((attach, index) => {
            return index !== id;
        });

        setAttachments(newData);
        onChange(newData);
    }

    return (
        <div>
            <button className="btn btn-primary mt-3" onClick={() => fileInput.current && fileInput.current.click()}>{label}</button>

            <input
                ref={fileInput}
                multiple={false}
                accept=".jpeg, .jpg, .gif, .png, .doc, .docx, .pdf, .txt, .msg"
                type="file"
                onChange={ handleFileInput }
                style={{ display: "None" }}/>

            <div>
                { attachments.map((attachment, id) => {
                        function handleClick(e){
                            e.preventDefault();
                            removeAttachment(id)
                        }

                        return (
                            <div key={id} className="card mt-2 mb-2">
                                <div className="card-body attachmentCard">
                                    <div>
                                        <b>Filename:</b> {attachment.name}
                                    </div>
                                    <button className="btn btn-danger" onClick={handleClick}>Remove</button>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}
