import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { GetConfiguration } from "./authConfig";

GetConfiguration().then((authData) => {
  const msalInstance = new PublicClientApplication(authData.msalConfig);

  ReactDOM.render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={{ scopes: authData.authScope }} >
          <App authScope={authData.authScope} />
        </MsalAuthenticationTemplate>
      </MsalProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
  
})


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
