import { useState } from 'react'

export default function FormInput({label,invalidLabel, rowCount = 1, textArea = false, onChange, isValid = true}){

    const [hasBeenVisited, setHasBeenVisited] = useState(false);
    const invalidControl = !isValid && hasBeenVisited;

    function handleChange(e) {
        setHasBeenVisited(true);
        onChange(e.target.value);
    }

    return (
        <div className="form-group">
            <div className="d-inline-flex">
                <label>{label}</label>
                <label className="text-danger">*</label>
            </div>
            { textArea ?
                <textarea type="text" className={"form-control" + (invalidControl ? " border-danger" : "")} rows={rowCount} onChange={handleChange} /> :
                <input type="text" className={"form-control" + (invalidControl ? " border-danger" : "")} onChange={handleChange} />
            }
            { invalidControl && <label className="text-danger mt-1">{invalidLabel}</label>}
        </div>
    );
}