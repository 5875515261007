

export const isNotEmpty = (value) => value ? true : false;

export const isValidPhoneNumber = (value) => {
    const rxFullNumber = /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
    const rxQuickDial = /^[0-9]{4}$/;
    const rxFullInternal = /^[0-9]{8}$/;
    const rxFullInternalSpace = /^[0-9]{4} [0-9]{4}$/;

    return rxFullNumber.test(value) || rxQuickDial.test(value) || rxFullInternal.test(value) || rxFullInternalSpace.test(value);
};

export const isValidEmail = (value) => {
    const rxEmail = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,4}$/;
    return rxEmail.test(value);
};

export const isValidLocation = (value) =>{
    return isNotEmpty(value) && value !== '-- Select Location --';
}