import { useState } from 'react'

export default function FormLocation({label,isValid,invalidLabel,onChange}){

    const [hasBeenVisited, sethasBeenVisited] = useState(false);
    const invalidControl = !isValid && hasBeenVisited;

    function handleChange(e) {
        sethasBeenVisited(true)
        onChange(e.target.value);
    }

    return (
        <div className="form-group">
            <div className="d-inline-flex">
                <label>{label}</label>
                <label className="text-danger">*</label>
            </div>

            <select className={"form-control" + (invalidControl ? " border-danger" : "")} onChange={handleChange}>
                <option>-- Select Location --</option>
                <option>Administration - 42 Wellington Street</option>
                <option>Administration - Mackay</option>
                <option>Administration - Mirani</option>
                <option>Administration - Sarina</option>
                <option>Animal Management Centre</option>
                <option>Artspace</option>
                <option>BB Print Stadium</option>
                <option>Blue Water Lagoon</option>
                <option>Botanic Gardens - Visitor Centre</option>
                <option>Café - Botanic Gardens</option>
                <option>Café - Foodspace</option>
                <option>Café - Riverfront</option>
                <option>Caravan Park - Mirani</option>
                <option>Community Hall - Andergrove Community</option>
                <option>Community Hall - Andergrove Neighbourhood</option>
                <option>Community Hall - Bakers Creek</option>
                <option>Community Hall - Bob Wood Sarina</option>
                <option>Community Hall - Glenella</option>
                <option>Community Hall - Mirani</option>
                <option>Community Hall - Northern Beaches</option>
                <option>Community Hall - Seaforth</option>
                <option>Community Hall - Seaforth Recreational</option>
                <option>Community Hall - Slade Point</option>
                <option>Community Hall - Tommy Webster Walkerston</option>
                <option>Council House (state address in specific location field)</option>
                <option>Depot - Paget Lunch Room</option>
                <option>Depot - Calen</option>
                <option>Depot - Eungella</option>
                <option>Depot - Mirani</option>
                <option>Depot - Ness Street</option>
                <option>Depot - Paget - Wash bay</option>
                <option>Depot - Paget Parks Operations</option>
                <option>Depot - Paget Administration</option>
                <option>Depot - Paget Amenities</option>
                <option>Depot - Paget Civil</option>
                <option>Depot - Paget Parks Services</option>
                <option>Depot - Paget Training Rooms</option>
                <option>Depot - Paget Warehouse</option>
                <option>Depot - Paget Water Services</option>
                <option>Depot - Paget Workshop</option>
                <option>Depot - Palmyra</option>
                <option>Depot - Sarina</option>
                <option>Field of Dreams Precinct</option>
                <option>Greenmount Homestead</option>
                <option>Jubilee Community Centre</option>
                <option>Leased Building (state building name and tenancy in specific location field)</option>
                <option>Library - Dudley Denny City</option>
                <option>Library - Gordon White</option>
                <option>Library - Mirani</option>
                <option>Library - Sarina</option>
                <option>Library - Walkerston</option>
                <option>MARC</option>
                <option>MECC - Convention Centre</option>
                <option>MECC - Entertainment Centre</option>
                <option>Melba House</option>
                <option>Other - provide details in specific location field</option>
                <option>Pool - Memorial</option>
                <option>Pool - Mirani</option>
                <option>Pool - Pioneer</option>
                <option>Pool - Sarina</option>
                <option>Sarina Landcare House</option>
                <option>Sarina Sugar Shed</option>
                <option>Sarina Youth Centre</option>
                <option>Senior Citizens Building</option>
                <option>SES - Armstrong's Beach</option>
                <option>SES - Calen</option>
                <option>SES - Campwin Beach</option>
                <option>SES - Eungella</option>
                <option>SES - Finch Hatton</option>
                <option>SES - Grasstree Beach</option>
                <option>SES - Koumala</option>
                <option>SES - Midge Point</option>
                <option>SES - Mirani</option>
                <option>SES - Ness Street</option>
                <option>SES - Sarina</option>
                <option>Stockpile Site</option>
                <option>Swayne Street Nursery</option>
                <option>Town Hall</option>
                <option>Youth Centre Sarina</option>
            </select>

            { invalidControl && <label class="text-danger mt-1">{invalidLabel}</label>}
        </div>
    )
}