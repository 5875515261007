import React, { useContext, useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react'

import { FormDataContext }from '../FormContext'
import { isNotEmpty, isValidEmail, isValidPhoneNumber, isValidLocation } from '../FormValidation'

import MRCLogo from "./MRCLogo";
import FormTitle from './FormTitle';

import './BMRForm.css'

import SuccessMessage from './SuccessMessage'
import ErrorMessage from './ErrorMessage'
import FormInput from './Fields/FormInput';
import FormButton from './Fields/FormButtom';
import FormCheckbox from './Fields/FormCheckbox';
import FormAttachments from './Fields/FormAttachments';
import FormLocation from './Fields/FormLocation';
import FormText from './Fields/FormText';
import BadAttachmentMessage from './BadAttachmentMessage';

export default function BMRForm({authScope}) {
    const formData = useContext(FormDataContext);

    const [displayState, setDisplayState] = useState({ isLoading: false, showForm: true, showError: false, showSuccess: false, showAttachmentError: false});
    const [workRequest, setWorkRequest] = useState("");
    const [ authKey, setAuthKey ] = useState('');
    const { instance, accounts } = useMsal();

    const fullnameValid = isNotEmpty(formData.fullName);
    const emailValid = isValidEmail(formData.email);
    const phonenumberValid = isValidPhoneNumber(formData.phoneNumber)
    const issueValid = isNotEmpty(formData.issue);
    const urgentIssueValid = formData.urgentIssue ? isNotEmpty(formData.urgentReason) : true;
    const locationValid = isValidLocation(formData.location);
    const specificLocationValid = isNotEmpty(formData.specificLocation);

    const canSubmitForm = fullnameValid &&
                          emailValid &&
                          phonenumberValid &&
                          issueValid &&
                          urgentIssueValid &&
                          locationValid &&
                          specificLocationValid;

    useEffect(() => {
        const accessTokenRequest = {
            scopes: authScope,
            account: accounts[0]
        }

        instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
            setAuthKey(accessTokenResponse.accessToken);
        }).catch((error) => {
            console.log(error);
        })

    }, [instance, accounts, authScope])

    function HandleFormSumit()
    {
        setDisplayState({
            ...displayState,
            isLoading: true
        })

        fetch('/submit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + authKey,
                },
                body: JSON.stringify(formData)
            })
            .then(res => res.json())
            .then(data => {
                if(data.status === 'error')
                {
                    setDisplayState({
                        ...displayState,
                        showError: true,
                        isLoading: false
                    });
                }
                else if(data.status === 'bad_attachments' || data.status === 'too_many') {
                    setWorkRequest(data.workRequest);

                    setDisplayState({
                        ...displayState,
                        isLoading: false,
                        showError: false,
                        showSuccess: false,
                        showForm: false,
                        showAttachmentError: true
                    });
                }
                else if(data.status === 'success') {
                    setWorkRequest(data.workRequest);

                    setDisplayState({
                        ...displayState,
                        isLoading: false,
                        showError: false,
                        showSuccess: true,
                        showForm: false
                    });
                }
            })
            .catch(error => {
                console.error(error);

                setDisplayState({
                    ...displayState,
                    showError: true,
                    isLoading: false
                });
            });
    }

    if(!authKey) return <div></div>;

    return (
        <div className="page">
            <div className="container-fluid">
                <MRCLogo />

                <FormTitle text="Building Maintenance Request Form" />

                { displayState.showSuccess && <SuccessMessage workRequest={workRequest} /> }
                { displayState.showAttachmentError && <BadAttachmentMessage workRequest={workRequest} /> }
                { displayState.showError && <ErrorMessage /> }

                { displayState.showForm &&
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-6">

                            <FormInput
                                label="Full Name:"
                                invalidLabel="Please enter your full name above!"
                                onChange={(value) => formData.setState({ fullName: value })}
                                isValid={ fullnameValid } />

                            <FormInput
                                label="Email Address:"
                                invalidLabel="Please enter your email above!"
                                onChange={(value) => formData.setState({ email: value })}
                                isValid={ emailValid } />

                            <FormInput
                                label="Contact Number:"
                                invalidLabel="Please enter your phone number above!"
                                onChange={(value) => formData.setState({ phoneNumber: value })}
                                isValid={ phonenumberValid } />

                            <FormInput
                                label="Issue:"
                                rowCount="8"
                                textArea
                                invalidLabel="Please enter your issue above!"
                                onChange={(value) => formData.setState({ issue: value })}
                                isValid={ issueValid } />

                            <FormCheckbox
                                label="Is this an Urgent issue?"
                                onChange={(value) => formData.setState({ urgentIssue: value, urgentReason: "" })}/>

                            { formData.urgentIssue &&
                                <FormInput
                                    label="Please explain why this request is urgent:"
                                    rowCount="3"
                                    textArea
                                    invalidLabel="Please explain why this request is urgent above!"
                                    onChange={(value) => formData.setState({ urgentReason: value })}/>
                            }

                            { formData.urgentIssue &&
                                <FormText 
                                    text="Please ensure you also phone Property Services during business hours and 1300 MACKAY for urgent after hour requests." 
                                    style={{ textAlign: "justify" }} />
                            }

                            <FormLocation
                                label="Location:"
                                invalidLabel="Please select your location above!"
                                onChange={(value) => formData.setState({ location: value })}
                                isValid={ locationValid } />

                            <FormInput
                                label="Specific Location:"
                                invalidLabel="Please enter a specific location above!"
                                onChange={(value) => formData.setState({ specificLocation: value })}
                                isValid={ specificLocationValid } />
                            
                            <FormText 
                                text="Please ensure relevant photos are attached to support your request (multiple photos including issue and surrounding area)" 
                                style={{ color: "Red", fontWeight: "Bold", textAlign: "justify" }} />

                            <FormAttachments
                                label="Add Attachments"
                                onChange={(value) => formData.setState({ attachments: value })} />

                            <FormButton label="Submit" enabled={ canSubmitForm } onClick={ HandleFormSumit } loading={displayState.isLoading} />

                        </div>
                    </div>
                }
            </div>
        </div>
    );
}
