import logo from '../Images/mrc-logo.png'


export default function MRCLogo(){
    return (
        <div className="row justify-content-center align-items-center">
            <img
            src={logo}
            alt="MRC Logo"
            className="m-4"
            style={{ width: "242px", height: "32px", padding: "0" }}
            />
        </div>
    );
}